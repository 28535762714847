<template>
  <div
    :class="isDetail ? 'detail' : ''"
    class="card-counter border rounded px-3 pb-2"
  >
    <h1 :class="`text-${typeCard}`">
      <animated-number
        :value="valueCard"
        :formatValue="formatValue"
        :duration="1000"
      />
    </h1>
    <h4>{{ title }}</h4>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'
export default {
  props: {
    typeCard: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    valueCard: {
      type: Number,
      required: true,
    },
    isDetail: {
      type: Boolean,
      default: () => false,
    },
    filterField: {
      type: Object,
    },
  },
  components: {
    AnimatedNumber,
  },
  methods: {
    formatValue(value) {
      return `${Number(value).toFixed(0)}`
    },
  },
}
</script>

<style lang="scss">
.card-counter {
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  &.detail {
    cursor: pointer;
    &:hover {
      transform: translate(0, -5px);
      box-shadow: 0px 0px 20px #0419301a;
    }
  }
}
</style>
